@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Coiny&family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

.blue-stroke-text{
    color: #5DB0FD;
    /* -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #5DB0FD; */
}

.blue-stroke-text-small{
    color: #5DB0FD;
    /* -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #5DB0FD; */
}


.blue-stroke-text-xsmall{
    color: #5DB0FD;
    /* -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #5DB0FD; */
}


.pink-stroke-text{
    color: #EA5D63;
    /* -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 4px;
    -webkit-text-stroke-color: #EA5D63; */
}

.pink-stroke-text-small{
    color: #EA5D63;
   /*  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    /* -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #EA5D63; */
}
.light-pink-border{
    border: 3px dashed #EA5D63;
}

.soft-gradient-circle{
    width: 20vw!important;
    height: 20vw!important;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 97, 151, 0.2) 0.01%, rgba(255, 113, 162, 0) 100%);
}

.what-is-gf-section{
    background-image: url("./assets/img/what-is-gf-bg.svg");
    background-size: cover;
}

.roadmap-section{
    background-image: url("./assets/img/roadmap-bg.svg");
    background-size: cover;
}

.who-are-we-section{
    background-image: url("./assets/img/team-bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.faq-section{
    background-image: url("./assets/img/faq-bg.svg");
    background-size: cover;
}

.hero-section{
    background-image: url("./assets/img/hero-section-bg.png");
    background-size: cover;
    background-position: center center;
}

@media (max-width: 540px) {
    .hero-section{
        background-image: url("./assets/img/hero-section-bg-mobile.png");
    }
}

/* @media (max-width: 1500px) {
    .blue-stroke-text,.pink-stroke-text{
        -webkit-text-stroke-width: 2px!important;
    }
    .blue-stroke-text-small{
        -webkit-text-stroke-width: 1.5px!important;
    }
} */